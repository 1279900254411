<template>
  <div class="profileEdit">
    <div class="profileEdit__title">
      {{ $t('profileEdit.title') }}
    </div>

    <div class="profileEdit__subTitle">
      {{ $t('profileEdit.contactDetailsTitle') }}
    </div>

    <Form
      v-bind:validation-schema="validationSchema"
      v-bind:initial-values="formData"
      v-slot="{ handleSubmit, isSubmitting }"
      as="div"
    >
      <base-form v-on:submit="handleSubmit($event, onSubmit)">
        <base-form-element class="profileEdit__emailField">
          <base-form-label for-id="email">
            {{ $t('form.emailLabel') }}
          </base-form-label>

          <base-form-input
            type="email"
            id="email"
          />

          <base-form-element-error name="email" />
        </base-form-element>

        <base-form-element class="profileEdit__phoneField">
          <base-form-label for-id="mobile_number">
            {{ $t('profileEdit.phonenumberLabel') }}
          </base-form-label>

          <base-form-input
            id="mobile_number"
          />

          <base-form-element-note>
            <router-link
              v-bind:to="{ name: 'profile.privacy'}"
              class="profileEdit__noteLink"
            >
              {{ $t('profileEdit.phonenumberNote') }}
            </router-link>
          </base-form-element-note>
        </base-form-element>

        <div class="profileEdit__subTitle">
          {{ $t('profileEdit.PersonalDetailsTitle') }}
        </div>

        <div class="profileEdit__nameFields">
          <base-form-element class="profileEdit__nameField">
            <base-form-label for-id="first_name">
              {{ $t('profileEdit.firstnameLabel') }}
            </base-form-label>

            <base-form-input
              type="firstName"
              id="first_name"
            />

            <base-form-element-error name="first_name" />
          </base-form-element>

          <base-form-element class="profileEdit__nameField">
            <base-form-label for-id="last_name">
              {{ $t('profileEdit.lastnameLabel') }}
            </base-form-label>

            <base-form-input
              type="lastName"
              id="last_name"
            />

            <base-form-element-error name="last_name" />
          </base-form-element>
        </div>

        <base-form-element>
          <base-form-label for-id="country">
            {{ $t('profileEdit.countryLabel') }}
          </base-form-label>

          <base-form-select
            id="country"
            class="profileEdit__select"
            v-bind:options="countryOptions"
          />

          <base-form-element-error name="country" />
        </base-form-element>

        <base-form-element>
          <base-form-label for-id="birthdate">
            {{ $t('profileEdit.birthdateLabel') }}
          </base-form-label>

          <base-form-date-input
            id="birthdate"
            v-bind:field-names="['birthdate_day', 'birthdate_month', 'birthdate_year']"
          />
        </base-form-element>

        <base-form-element>
          <base-form-label>
            {{ $t('profileEdit.genderLabel') }}
          </base-form-label>

          <br />

          <base-form-radio-button-group
            v-bind:options="genderOptions"
            v-bind:value="formData.gender"
            name="gender"
          />
        </base-form-element>

        <base-form-element>
          <base-button
            modifiers="primary"
            v-bind:show-spinner="isSubmitting"
            v-bind:disabled="isSubmitting"
            v-bind:loading-text="$t('profileEdit.savingButtonLabel')"
          >
            {{ $t('profileEdit.saveButtonLabel') }}
          </base-button>
        </base-form-element>
      </base-form>
    </Form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import BaseForm from '@/components/forms/BaseForm';
import BaseFormElement from '@/components/forms/BaseFormElement';
import BaseFormElementNote from '@/components/forms/BaseFormElementNote';
import BaseFormElementError from '@/components/forms/BaseFormElementError';
import BaseFormLabel from '@/components/forms/BaseFormLabel';
import BaseFormInput from '@/components/forms/BaseFormInput';
import BaseFormSelect from '@/components/forms/BaseFormSelect';
import BaseFormDateInput from '@/components/forms/BaseFormDateInput';
import BaseFormRadioButtonGroup from '@/components/forms/BaseFormRadioButtonGroup';
import BaseButton from '@/components/BaseButton';
import { i18n } from '@/plugins/i18n';

export default {
  components: {
    Form,
    BaseForm,
    BaseFormElement,
    BaseFormElementNote,
    BaseFormElementError,
    BaseFormLabel,
    BaseFormInput,
    BaseFormSelect,
    BaseFormDateInput,
    BaseFormRadioButtonGroup,
    BaseButton,
  },

  data() {
    return {
      countryOptions: [
        { name: 'Nederland', value: 'NL' },
        { name: 'België', value: 'BE' },
      ],
      genderOptions: [
        { label: this.$t('profileEdit.genderLabelWoman'), value: 'f', id: 'female' },
        { label: this.$t('profileEdit.genderLabelMan'), value: 'm', id: 'male' },
        { label: this.$t('profileEdit.genderLabelUndisclosed'), value: '9', id: 'undisclosed' },
      ],
      formData: {
        email: '',
        mobile_number: '',
        first_name: '',
        last_name: '',
        country: '',
        birthdate_day: '',
        birthdate_month: '',
        birthdate_year: '',
        gender: '',
      },
      validationSchema: yup.object({
        email: yup
          .string()
          .email(() => i18n.global.t('form.errorEmail'))
          .required(() => i18n.global.t('form.errorRequired')),
        first_name: yup
          .string()
          .required(() => i18n.global.t('form.errorRequired')),
        last_name: yup
          .string()
          .required(() => i18n.global.t('form.errorRequired')),
        country: yup
          .string()
          .required(() => i18n.global.t('form.errorRequired')),
      }),
    };
  },

  computed: {
    ...mapState('member', ['profile']),
  },

  async created() {
    await this.fetchProfile();

    Object
      .keys(this.formData)
      .forEach(key => this.formData[key] = this.profile[key]);
  },

  methods: {
    ...mapActions('member', ['fetchProfile', 'updateProfile']),

    onSubmit(values) {
      this.updateProfile(values)
        .then(this.onSettingsSaved)
        .catch(this.onSettingsSaveError);
    },

    onSettingsSaved() {
      this.$router.push({ name: 'profile.overview' });
    },

    onSettingsSaveError(error) {
      console.warn(error);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.profileEdit__title {
  @include heading-5;
  margin: 0 0 rem(24px) 0;
}

.profileEdit__subTitle {
  @include section--bold;
  margin: rem(32px) 0 rem(18px) 0;
  padding: 0 0 rem(4px) 0;
  color: $color-grey--medium;
  border-bottom: 1px solid $color-grey--light;
}

.profileEdit__emailField,
.profileEdit__phoneField {
  max-width: 250px;
}

.profileEdit__noteLink {
  text-decoration: underline;
}

.profileEdit__nameFields {
  margin: 0 0 rem(16px) 0;

  @include desktop {
    display: flex;
    justify-content: space-between;
    margin: 0;
  }
}

.profileEdit__nameField {
  &:first-child {
    flex: 0 0 calc(40% - #{rem(8px)});
  }

  &:last-child {
    flex: 0 0 calc(60% - #{rem(8px)});
  }
}

.profileEdit__select {
  max-width: 210px;
}
</style>
