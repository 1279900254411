<template>
  <div v-bind:class="{
    'baseFormRadioButtonGroup': true,
    [$attrs.class]: true,
  }">
    <base-form-radio-button
      v-for="(option, index) in options"
      v-bind:key="index"
      v-bind:name="name"
      v-bind:id="option.id"
      v-bind:value="`${option.value}`"
      v-bind:label="option.label"
      class="baseFormRadioButtonGroup__radioButton"
    />
  </div>
</template>

<script>
import BaseFormRadioButton from '@/components/forms/BaseFormRadioButton';

export default {
  inheritAttrs: false,

  components: {
    BaseFormRadioButton,
  },

  props: {
    options: {
      type: Array,
      required: true,
      validator: value => value.every(({ label, value, id }) => {
        return !!label && !!value && !!id;
      }),
    },

    name: {
      type: String,
      required: true,
    },

    value: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.baseFormRadioButtonGroup {
  display: inline-flex;
  align-items: center;
  padding: rem(10px) rem(24px) rem(6px) rem(14px);
  border-radius: rem(6px);
  border: 1px solid $color-beige--dark;
}

.baseFormRadioButtonGroup__radioButton {
  margin: 0 rem(24px) 0 0;

  &:last-child {
    margin: 0;
  }
}
</style>