<template>
  <div class="baseFormDateInput">
    <Field
      v-for="(fieldName, index) in fieldNames"
      v-bind:key="index"
      v-bind:name="fieldName"
      v-slot="{ field }"
      class="baseFormDateInput__fieldWrapper"
      as="div"
    >
      <input
        v-bind="{
          ...field,
          ...(index === 0 ? $attrs : {}),
        }"
        type="number"
        class="baseFormDateInput__field"
      >
    </Field>
  </div>
</template>

<script>
import { Field } from 'vee-validate';

export default {
  components: {
    Field,
  },

  props: {
    fieldNames: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.baseFormDateInput {
  display: flex;
  justify-content: flex-start;
}

.baseFormDateInput__fieldWrapper {
  flex: 0 0 70px;
  border: 1px solid $color-beige--dark;

  &:first-child {
    border-top-left-radius: rem(6px);
    border-bottom-left-radius: rem(6px);
    border-right: none;
  }

  &:last-child {
    border-top-right-radius: rem(6px);
    border-bottom-right-radius: rem(6px);
    border-left: none;
  }
}

.baseFormDateInput__field {
  @include paragraph;
  padding: rem(8px);
  width: 100%;
  border: none;
  background: none;
  color: $color-text;
  appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
  }
}
</style>