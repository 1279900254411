<template>
  <Field
    v-bind:name="$attrs.name"
    v-bind:value="value"
    v-slot="{ field }"
    v-bind:class="{
      'formRadioButton': true,
      [$attrs.class]: true,
    }"
    type="radio"
    as="div"
  >
    <input
      v-bind="{ ...field }"
      v-bind:id="$attrs.id"
      v-bind:value="value"
      type="radio"
      class="formRadioButton__input"
    />

    <label
      v-bind:for="$attrs.id"
      class="formRadioButton__label"
    >
      {{ label }}
    </label>
  </Field>
</template>

<script>
import { Field } from 'vee-validate';

export default {
  inheritAttrs: false,

  components: {
    Field,
  },

  props: {
    value: {
      type: String,
      required: true,
    },

    label :{
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.formRadioButton__label {
  @include paragraph;
  display: inline-flex;
  align-items: center;
  position: relative;
  color: $color-text;
  cursor: pointer;

  &:before {
    content: "";
    flex: 0 0 auto;
    margin: 0 rem(8px) 0 0;
    width: rem(16px);
    height: rem(16px);
    border-radius: 50%;
    border: 1px solid $color-beige--dark;
    background: #fff;
  }

  &:after {
    content: "";
    display: none;
    position: absolute;
    left: rem(3px);
    width: rem(10px);
    height: rem(10px);
    border-radius: 50%;
    background: $color-blue;
  }
}

.formRadioButton__input {
  display: none;

  &:checked+.formRadioButton__label:after {
    display: block;
  }
}
</style>